.form-suggestion {
  position: relative;
}

.form-suggestion-control {
  outline: none;
  width: 100%;
  min-height: 3rem;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  padding: 0.35rem;
  color: var(--color-black);
  background: var(--color-white);
  border: 1px solid var(--grey-color-700);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-suggestion-control:focus {
  border-color: #0176d3;
  box-shadow: #0176d3 0px 0px 3px 0px;
}

.form-suggestion-control:disabled {
  background: var(--grey-color-500)
}

.form-suggestion-control::placeholder {
  color: var(--grey-color-200);
  font-size: 1.3rem;
  font-weight: 500;
}

.form-suggestion-list {
  position: absolute;
  width: 100%;
  max-height: 250px;
  border-radius: 0.5rem;
  z-index: 2;
  overflow: auto;
  overflow-x: hidden;
  background-color: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.form-suggestion-list button {
  cursor: pointer;
  border: none;
  text-align: left;
  font-size: 1.2rem;
  padding: 1rem;
  width: 100%;
  max-height: 5.5rem;
  color: var(--color-black);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--grey-color-700);
}

.form-suggestion-list button:hover {
  color: var(--color-white);
  background: var(--grey-color-700);
}

.form-suggestion-list button:last-child {
  border-bottom: none;
}

@media (max-width: 1024px) {
  .form-suggestion-control {
    font-size: 1.2rem;
    min-height: 2.8rem;
  }
}

@media (max-width: 600px) {
  .form-suggestion-control {
    font-size: 1.1rem;
    min-height: 2.6rem;
  }
}

@media (max-width: 480px) {
  .form-suggestion-control {
    font-size: 1rem;
    min-height: 2.4rem;
  }
}
