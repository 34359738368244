.header {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4vh;
  font-size: 1.4rem;
  color: var(--color-white);
  background-color: var(--blue-color-600);
}

.header-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header-logo {
  height: 2.6rem;
  width: 2.6rem;
  overflow: hidden;
  object-fit: cover;
}

.header-title {
  font-size: 1.6rem;
  font-weight: bold;
}

.header-transfer {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.header-transfer a {
  text-decoration: none;
  color: white;
}

.header-transfer a:hover {
  color: black;
}

.header-transfer li {
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.header-transfer img {
  position: relative;
  padding: 0;
  margin: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-transfer img:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.header-menu {
  display: none;
}

@media (max-width: 1200px) {
  .header-title {
    font-size: 1.5rem;
  }

  .header-transfer {
    gap: 1.5rem;
  }

  .header-transfer li {
    font-size: 1.4rem;
  }
}

@media (max-width: 1024px) {
  .header-title {
    font-size: 1.4rem;
  }

  .header-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-menu:hover {
    cursor: pointer;
  }
  
  .header-menu img {
    width: 2.8rem;
    height: 2.8rem;
    background: white;
    border-radius: 50%;
    padding: 0.5rem;
  }

  .header-transfer {
    display: none;
  }

  .header-transfer.active {
    display: block;
    position: absolute;
    padding: 0.3rem;
    top: 4vh;
    right: 0.5rem;
    flex-direction: column;
    gap: 0.2rem;
    width: auto;
    height: auto;
    background: white;
    z-index: 3;
    border: solid 0.1rem var(--blue-color-600);
    border-radius: 0.5rem;
  }

  .header-transfer li {
    font-size: 1.2rem;
    width: 100%;
    margin: 0.2rem;
    padding: 0.5rem;
    background: var(--blue-color-600);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
  }

  .header-transfer li:hover {
    background: var(--blue-color-800);
  }

  .header-transfer a:hover {
    color: white;
  }
}

@media (max-width: 600px) {
  .header-container {
    padding: 0 1rem;
  }

  .header-content {
    gap: 0.5rem;
  }

  .header-logo {
    height: 2rem;
    width: 2rem;
  }

  .header-title {
    font-size: 1.3rem;
  }

  .header-transfer li {
    font-size: 1.1rem;
  }

  .header-menu img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .header-transfer img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@media (max-width: 480px) {
  .header-title {
    font-size: 1.2rem;
  }

  .header-transfer li {
    font-size: 1rem;
  }
}
