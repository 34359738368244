.layer-roles {
  position: relative;
  margin: 0.1rem;
  padding: 0;
  width: 100%;
  height: 20rem;
  background: white;
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--blue-color-800);
  display: none;
  border-radius: 0.5rem;
}

.layer-roles.active {
  display: block;
}

.layer-roles-title {
  position: relative;
  width: 100%;
  height: 2.4rem;
  background: var(--grey-color-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-inter);
  font-weight: 600;
  font-size: 1.2rem;
  color: white;
  border-radius: 0.5rem 0.5rem 0 0;
}

.layer-roles-title span {
  margin-left: 2rem;
}

.layer-roles-title .form-check {
  margin-right: 1rem;
  width: 1.3rem;
  cursor: pointer;
}

.layer-roles-content {
  width: 100%;
  height: calc(100% - 2.5rem);
}

.layer-roles-list {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  list-style-type: none;
  background: white;
}

.layer-roles-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.3rem 0.3rem 0.3rem 1.5rem;
  border-radius: 0.3rem;
}

.layer-roles-list li label {
  cursor: pointer;
  color: var(--blue-color-800);
  width: 100%;
  margin-left: 1rem;
}

.layer-roles-list li:hover {
  background: var(--grey-color-50);
}

@media (max-width: 460px) {
  .layer-roles {
    width: 100%;
  }
  .layer-roles-title span {
    font-size: 1.1rem;
    margin-left: 0.5rem;
  }
  .layer-roles-title input {
    margin-right: 0.5rem;
  }
  .layer-roles-list li {
    padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  }
  .layer-roles-list li label {
    margin-left: 0.5rem;
    font-size: 1rem;
  }
}
