.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: var(--grey-color-50);
}

.modal-body,
.modal-footer {
  padding: 0.5rem;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  background-color: var(--blue-color-800);
  border-bottom: 1px solid var(--grey-color-800);
  border-radius: 0.5rem 0.5rem 0 0;
}

.modal-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
}

.modal-close {
  border: none;
  font-size: 2.5rem;
  padding: 0 0.25rem;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
  color: black;
}

.modal-close:hover {
  color: white;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  border-top: 1px solid var(--grey-color-800);
  background: var(--blue-color-800);
  border-radius: 0 0 0.5rem 0.5rem;
}

.modal-footer > * {
  margin: 0 0.25rem;
}

@media screen and (max-width: 1024px) {
  .modal-title {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .modal-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .modal-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .modal-title {
    font-size: 1.1rem;
  }
}
