.tab-layer {
  position: relative;
  height: 100%;
  width: 100%;
}

.select-district {
  padding: 0.2rem;
  height: 3.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.basemap {
  position: relative;
  height: 18.3rem;
}

.thematicmap {
  position: relative;
  height: calc(100% - 21.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basemap-title,
.thematicmap-title {
  position: relative;
  height: 2.3rem;
  width: 100%;
  background-color: var(--blue-color-600);
  box-shadow: 0 6.42px 6.42px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.basemap-title span,
.thematicmap-title span {
  font-size: 1.2rem;
  display: inline-block;
  color: var(--color-white);
  font-family: var(--font-inter);
  font-weight: 600;
}

.basemap-container,
.thematicmap-container {
  position: relative;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: calc(100% - 2.3rem);
  width: 100%;
  padding: 1rem;
}

.basemap-container::-webkit-scrollbar,
.thematicmap-container::-webkit-scrollbar {
  display: none;
}

.basemap-list {
  position: relative;
  list-style-type: none;
}

.basemap-list li {
  margin: 0.2rem 0;
  width: 100%;
  background: var(--grey-color-50);
  border-radius: 0.3rem;
  cursor: pointer;
}

.basemap-list li:hover {
  background: var(--grey-color-100);
}

.basemap-list .basemap-checkbox {
  margin: 0;
  padding: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
}

.basemap-list .basemap-checkbox input {
  cursor: pointer;
}

.basemap-list .basemap-checkbox label {
  font-weight: 600;
  color: var(--blue-color-800);
  cursor: pointer;
}

.thematicmap-according {
  position: relative;
  margin: 0.2rem 0;
  padding: 0.5rem;
  width: 100%;
  background: var(--grey-color-50);
  border-radius: 0.3rem;
}

.thematicmap-according .thematicmap-according-title {
  background: transparent;
  border: none;
  font-weight: 600;
  color: var(--blue-color-800);
  font-size: 1.2rem;
  font-family: var(--font-inter);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7rem;
}

.thematicmap-according:hover {
  background: var(--grey-color-100);
}

.thematicmap-according .thematicmap-according-title span {
  width: 100%;
  display: block;
  text-align: left;
}

.thematicmap-according .thematicmap-according-title img {
  height: 1.6rem;
  width: 1.6rem;
}

.thematicmap-according .thematicmap-according-title:before {
  content: url(src/assets/img/frame-open.png);
  margin: 0;
  padding: 0;
}

.thematicmap-according .thematicmap-according-content {
  position: relative;
  width: 100%;
  max-height: 0rem;
  overflow: hidden;
  transition: max-height 0.3s;
}

.thematicmap-according.active .thematicmap-according-content {
  position: relative;
  top: 3px;
  width: 100%;
  max-height: 15rem;
  overflow: scroll;
  overflow-x: hidden;
  border: solid 0.03rem var(--grey-color-700);
  border-radius: 0.5rem 0 0 0.5rem;
  background: white;
}

.thematicmap-according.active .thematicmap-according-title:before {
  content: url(src/assets/img/frame-close.png);
  margin: 0;
}

.thematicmap-according-list {
  position: relative;
  list-style-type: none;
  padding: 0.3rem;
  height: 100%;
}

.thematicmap-according-list li {
  margin: 0.2rem;
  padding: 0.1rem 0.1rem 0.1rem 0.4rem;
  background: rgb(220, 240, 245);
  border-radius: 0.3rem;
  cursor: pointer;
}

.thematicmap-according-list li:hover {
  background: rgb(176, 202, 204);
}

.thematicmap-according-list .thematicmap-checkbox-item {
  padding: 0.5rem;
}

.thematicmap-according-list .thematicmap-checkbox-item label {
  font-weight: 600;
  font-size: 1.1rem;
  width: 100%;
  margin-left: 0.5rem;
}

@media (max-width: 1024px) {
  .select-district {
    height: 3rem;
  }

  .basemap {
    height: 18.5rem;
  }

  .basemap-title span,
  .thematicmap-title span {
    font-size: 1.1rem;
  }

  .basemap-container,
  .thematicmap-container {
    padding: 0.8rem;
  }

  .basemap-list .basemap-checkbox {
    padding: 0.3rem;
  }

  .thematicmap-according .thematicmap-according-title {
    font-size: 1.1rem;
    gap: 0.6rem;
  }

  .thematicmap-according .thematicmap-according-title img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .thematicmap-according-list .thematicmap-checkbox-item {
    padding: 0.45rem;
  }
  
  .thematicmap-according-list .thematicmap-checkbox-item label {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .select-district {
    height: 2.8rem;
  }

  .basemap {
    height: 18.7rem;
  }

  .basemap-title span,
  .thematicmap-title span {
    font-size: 1rem;
  }

  .basemap-container,
  .thematicmap-container {
    padding: 0.6rem;
  }

  .thematicmap-according .thematicmap-according-title {
    font-size: 1rem;
    gap: 0.5rem;
  }

  .thematicmap-according .thematicmap-according-title img {
    width: 1.4rem;
    height: 1.4rem;
  }

  .thematicmap-according-list .thematicmap-checkbox-item {
    padding: 0.4rem;
  }
  
  .thematicmap-according-list .thematicmap-checkbox-item label {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .select-district {
    height: 2.6rem;
  }

  .basemap {
    height: 18.9rem;
  }

  .basemap-title span,
  .thematicmap-title span {
    font-size: 1rem;
  }

  .basemap-container,
  .thematicmap-container {
    padding: 0.4rem;
  }

  .thematicmap-according .thematicmap-according-title {
    font-size: 1rem;
    gap: 0.5rem;
  }

  .thematicmap-according .thematicmap-according-title img {
    width: 1.3rem;
    height: 1.3rem;
  }

  .thematicmap-according-list .thematicmap-checkbox-item {
    padding: 0.35rem;
  }
  
  .thematicmap-according-list .thematicmap-checkbox-item label {
    font-size: 0.8rem;
  }
}
