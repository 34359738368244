.tabs-container {
  height: 100%;
  width: 100%;
  z-index: 2;
}
.tabs-control {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 2.6rem;
  background-color: var(--blue-color-600);
  border-radius: 1rem 1rem 0 0;
}

.tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 100%;
  width: 30%;
  border-radius: 1rem 1rem 0 0;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  transition: all 0.5s ease;
  background: var(--grey-color-50);
  background: linear-gradient(
    0deg,
    var(--grey-color-50),
    var(--grey-color-200) 100%
  );
  color: black;
  border: solid 0.1rem var(--grey-color-700);
  transition: all 0s;
}

.tab-item:hover {
  background: white;
}

.tab-item.active {
  opacity: 1;
  color: white;
  background: var(--grey-color-700);
}

.tabs-content {
  width: 100%;
  height: calc(100% - 2.6rem);
}

.tab-pane {
  color: #333;
  display: none;
  height: 100%;
}

.tab-pane.active {
  display: block;
}
@media (max-width: 1310px) {
  .tabs-control {
    height: 4rem;
  }
}
@media (max-width: 1040px) {
  .tabs-control {
    height: 5rem;
  }
}
