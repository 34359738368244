.attributetable-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.attributetable-container .attributetable-content {
  width: 100%;
  height: 100%;
}
