.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: auto;
  min-height: 2rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: var(--font-inter);
  cursor: pointer;
  border: transparent;
  user-select: none;
  color: var(--color-black);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(232, 232, 232, 1) 100%
  );
}

.btn:hover {
  background: var(--color-white);
  box-shadow: 0.1rem 0.2rem 0.3rem var(--grey-color-700);
}

.btn:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}

@media (max-width: 1024px) {
  .btn {
    font-size: 1.1rem;
    min-height: 1.8rem;
    min-width: 90px;
  }
}

@media (max-width: 600px) {
  .btn {
    font-size: 1rem;
    min-height: 1.6rem;
    min-width: 80px;
  }
}

@media (max-width: 480px) {
  .btn {
    font-size: 1rem;
    min-height: 1.4rem;
    min-width: 70px;
  }
}

/* Button types */
.rounded {
  border-radius: 999px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
  border: 1px solid rgba(22, 24, 35, 0.12);
}

.rounded:hover {
  border: 1px solid rgba(22, 24, 35, 0.2);
  background: rgba(22, 24, 35, 0.03);
}

.outline {
  color: var(--color-black);
  border: 1px solid var(--grey-color-700);
}

.outline:hover {
  border: 1px solid var(--grey-color-800);
  background: rgba(254, 44, 85, 0.06);
}

/* Button sizes */
.small {
  min-width: 70px;
}

.large {
  min-width: 140px;
}
