.tab-direction {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  background: white;
}

.direction-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1rem;
  width: 100%;
  height: 2.5rem;
  background: var(--blue-color-600);
  box-shadow: 0 6.42px 6.42px rgba(0, 0, 0, 0.25) inset;
}

.direction-title span {
  font-family: var(--font-inter);
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.direction-container {
  padding: 0.5rem;
  position: relative;
  height: calc(100% - 2.5rem);
  overflow: scroll;
  overflow-x: hidden;
}

.direction-notes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
}

.direction-notes h3 {
  text-align: center;
}

.direction-notes-click {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.direction-routes {
  height: auto;
  width: auto;
}

.directions-summary {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  background: var(--grey-color-800);
  padding: 5px 10px;
}

.directions-summary h1,
.directions-summary span {
  color: var(--color-white);
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 28px;
  margin: 0;
}

.directions-instructions {
  background: var(--color-black);
  position: relative;
}

.directions-instructions .directions-icon {
  position: absolute;
  left: 12px;
  top: 20px;
  margin: auto;
}

.directions-instructions .directions-icon:before {
  vertical-align: top;
}

.directions-step-estimate {
  color: yellow;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 5px 10px;
  font-size: 1.2rem;
  left: 30px;
  bottom: -10px;
}

.directions-steps {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.directions-step {
  position: relative;
  color: white;
  cursor: pointer;
  padding: 20px 20px 20px 40px;
  font-size: 1.3rem;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: 0.1em;
}

.directions-step * {
  pointer-events: none;
}

.directions-step:hover {
  color: var(--color-white);
}

.directions-step:after {
  content: "";
  position: absolute;
  top: 45px;
  bottom: -10px;
  border-left: 2px dotted var(--grey-color-700);
  left: 20px;
}

.directions-step:last-child:after,
.directions-step:last-child .directions-step-distance,
.directions-step:last-child .directions-step-duration {
  display: none;
}

@media (max-width: 1024px) {
  .direction-notes {
    font-size: 1.2rem;
  }

  .direction-title span {
    font-size: 1.1rem;
  }

  .directions-summary h1,
  .directions-summary span {
    font-size: 1.2rem;
  }

  .directions-step-estimate {
    font-size: 1.1rem;
    gap: 0.7rem;
  }

  .directions-step {
    font-size: 1.2rem;
    line-height: 17px;
    letter-spacing: 0;
  }
}

@media (max-width: 600px) {
  .direction-notes {
    font-size: 1.1rem;
  }

  .direction-title span {
    font-size: 1rem;
  }

  .directions-summary h1,
  .directions-summary span {
    font-size: 1.1rem;
  }

  .directions-step-estimate {
    font-size: 1rem;
    gap: 0.6rem;
  }

  .directions-step {
    font-size: 1.1rem;
    line-height: 17px;
    letter-spacing: 0;
  }
}

@media (max-width: 480px) {
  .direction-notes {
    font-size: 1rem;
  }

  .direction-title span {
    font-size: 1rem;
  }

  .directions-summary h1,
  .directions-summary span {
    font-size: 1rem;
  }

  .directions-step-estimate {
    font-size: 0.9rem;
    gap: 0.5rem;
  }

  .directions-step {
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 0;
  }
}
