.simple-search-form {
  min-width: 400px;
  width: 35vw;
  height: 100%;
}

.simple-search-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.simple-var {
  height: auto;
  width: 100%;
  background: white;
  padding: 0.3rem;
  border-radius: 0.3rem;
  border: solid 0.1rem var(--grey-color-200);
}

.simple-var-title {
  border-radius: 0.3rem 0.3rem 0 0;
  background: var(--blue-color-800);
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: var(--font-inter);
  padding: 0.3rem 2rem 0.3rem 2rem;
  width: 120px;
  text-align: center;
}

/* DDMMYYYY */
.simple-var-where {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
  width: 100%;
}

.simple-var-where h3 {
  font-family: var(--font-inter);
  padding: 0.3rem;
  border-radius: 0.3rem 0.3rem 0 0;
  background: var(--blue-color-600);
  border: solid 0.1rem var(--blue-color-600);
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-condition {
  width: 30%;
  display: flex;
  flex-direction: column;
  border: solid 0.1rem var(--grey-color-200);
  border-radius: 0.3rem;
  gap: 0.2rem;
  padding: 0.2rem 0;
  height: fit-content;
}

.simple-condition .form-check {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.simple-condition .form-check label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  background: linear-gradient(0deg, white, var(--grey-color-200) 100%);
  border-radius: 0.3rem;
  color: var(--blue-color-800);
  border: solid 0.1rem var(--grey-color-700);
  font-weight: 600;
  padding: 0.3rem;
  margin: 0 0.2rem;
}

.simple-condition .form-check input {
  display: none;
}

.simple-condition .form-check input:checked + label {
  background: var(--color-white);
  color: var(--blue-color-600);
}

.simple-condition .form-check input:disabled + label {
  background: var(--grey-color-500);
  color: var(--blue-color-600);
  opacity: 0.5;
}

.simple-value {
  width: 70%;
  display: flex;
  gap: 0.5rem;
}

.simple-value-enter {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.simple-value-enter input {
  border-radius: 0 0 0.3rem 0.3rem;
}

/* MMinYYYY */
.simple-time-month {
  flex: 7;
}

.simple-month {
  display: flex;
  gap: 0.5rem;
  border: solid 0.1rem var(--grey-color-200);
  border-radius: 0 0 0.3rem 0.3rem;
  padding: 0rem 0.2rem 0.3rem 0.2rem;
}

.simple-month .form-check {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.simple-month .form-check label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue-color-600);
  font-weight: 600;
}

.simple-time-year {
  flex: 3;
  align-self: flex-start;
}

.simple-time-year input {
  border-radius: 0 0 0.3rem 0.3rem;
}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {
  .simple-var-title {
    font-size: 1.2rem;
  }

  .simple-var-where h3 {
    padding: 0.35rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .simple-var-title {
    font-size: 1.1rem;
  }

  .simple-var-where h3 {
    padding: 0.3rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .simple-var-where h3 {
    padding: 0.275rem;
  }
}
