/* Date Input */
.MuiFormControl-root {
  width: 100%;
  height: 3rem;
}

.MuiFormControl-root .MuiInputBase-root {
  font-family: var(--font-inter);
  font-size: 1.3rem;
  font-weight: 500;
  color: black;
  border-radius: 0 0 0.3rem 0.3rem;
  height: 100%;
}

.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0.5rem;
}

@media (max-width: 1024px) {
  .MuiFormControl-root {
    font-size: 1.2rem;
    height: 2.8rem;
  }
}

@media (max-width: 600px) {
  .MuiFormControl-root {
    font-size: 1.1rem;
    height: 2.6rem;
  }
}

@media (max-width: 480px) {
  .MuiFormControl-root {
    font-size: 1rem;
    height: 2.4rem;
  }
}
