.district-roles {
  position: relative;
  width: 100%;
  height: 100%;
  color: black;
  background: white;
  font-weight: 600;
  font-size: 1.3rem;
  z-index: 2;
}

.district-roles-title {
  width: 100%;
  height: 2.4rem;
  background: var(--grey-color-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 1rem;
}

.district-roles-title span {
  width: 15rem;
  height: 75%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.district-roles-title .form-check {
  width: 1.3rem;
  cursor: pointer;
  accent-color: yellow;
}

.district-roles-content {
  position: relative;
  width: 100%;
  height: calc(100% - 2.4rem);
  border-left: solid 0.1rem var(--grey-color-700);
  border-right: solid 0.1rem var(--grey-color-700);
}

.district-roles-list {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  list-style-type: none;
  background: white;
}

.district-roles-list li {
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  color: black;
  background: var(--grey-color-50);
  border-radius: 0.3rem;
  cursor: pointer;
}

.district-roles-list li:hover {
  background: var(--grey-color-100);
}

.district-roles-list li label {
  cursor: pointer;
  min-width: 30%;
  width: 100%;
}

@media (max-width: 420px) {
  .district-roles-title span {
    margin-left: 0.2rem;
  }

  .district-roles-title input {
    margin-right: 0.2rem;
  }

  .district-roles-list li {
    padding: 0.5rem;
    font-size: 1.1rem;
  }
}
