.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-toggle:hover {
  background-color: #0056b3;
}

.dropdown-menu {
  margin-top: 1vh;
  display: block;
  position: absolute;
  right: 0; 
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0.6rem;
  text-decoration: none;
  color:white;
  background-color: #1e89dd;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 0.2rem;
}


.dropdown li span:hover {
  color: black;
}
