.tab-attribute {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: white;
}

.attribute-bar {
  position: relative;
  margin: 0;
  background: var(--blue-color-600);
  box-shadow: 0 6.42px 6.42px rgba(0, 0, 0, 0.25) inset;
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8rem 0 2rem;
}

.attribute-bar .form-select {
  width: 18rem;
  min-height: 2rem;
  border-radius: 0.3rem;
}

.attribute-bar-title {
  position: relative;
  width: auto;
  font-family: var(--font-inter);
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
}

.attribute-bar-title button {
  height: 2rem;
  padding: 0.15rem 0.5rem;
  border-radius: 0.3rem;
}

.attribute-bar-title button:hover {
  background: white;
  box-shadow: 0.1rem 0.2rem 0.3rem var(--grey-color-700);
}

.attribute-bar-action {
  position: absolute;
  right: 1rem;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.attribute-bar-action .export-statistic {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
}

.attribute-bar-action .export-statistic:hover {
  background: transparent;
}

.attribute-bar-action .export-statistic:hover img {
  width: 2.3rem;
  height: 2.3rem;
}

.attribute-bar-action button {
  height: 2rem;
  padding: 0.15rem 0.5rem;
  border-radius: 0.3rem;
}

.attribute-bar-action button:hover {
  background: white;
  box-shadow: 0.1rem 0.2rem 0.3rem var(--grey-color-700);
}

.attribute-bar-action a {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0.1rem 0.2rem;
}

.attribute-bar-action a img {
  height: 1.8rem;
  width: 1.8rem;
}

.attribute-bar-action a img:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.attribute-bar .attribute-close {
  border: none;
  background: transparent;
}

.attribute-bar .attribute-close:hover {
  background: transparent;
}

.attribute-bar .attribute-close {
  height: 100%;
  display: flex;
  align-items: center;
}

.attribute-bar .attribute-close img {
  width: 1.75rem;
  height: 1.75rem;
}

.attribute-bar .attribute-close img:hover {
  filter: invert(100%);
}

.attribute-content {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 2.4rem);
  z-index: 1;
}

@media (max-width: 1024px) {  
  .attribute-bar {
    padding: 0 4rem 0 2rem;
  }

  .attribute-bar .form-select {
    width: 17rem;
  }

  .attribute-bar-title span {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .attribute-bar {
    flex-direction: column;
    height: 5rem;
    padding: 0.5rem;
  }

  .attribute-bar .form-select {
    width: 16rem;
  }

  .attribute-bar-title span {
    font-size: 1.2rem;
  }

  .attribute-content {
    height: calc(100% - 5rem);
  }
}

@media (max-width: 600px) {
  .attribute-bar .form-select {
    width: 15rem;
  }

  .attribute-bar-title span {
    font-size: 1.2rem;
  }
}
