.form-select {
  width: 100%;
  min-height: 3rem;
  padding: 0.075rem 0.5rem;
  font-family: var(--font-inter);
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 0.2rem;
  cursor: pointer;
  color: black;
  background: white;
  background: linear-gradient(
    0deg,
    white,
    var(--grey-color-200) 100%
  );
}

.form-select:focus {
  border: solid 0.1rem var(--blue-color-600);
  box-shadow: 0.1rem 0.2rem 0.3rem var(--blue-color-600);
}

.form-select option {
  font-family: var(--font-inter);
  font-size: 1.3rem;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .form-select {
    font-size: 1.2rem;
    min-height: 2.8rem;
  }

  .form-select option {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .form-select {
    font-size: 1.1rem;
    min-height: 2.6rem;
  }

  .form-select option {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .form-select {
    font-size: 1rem;
    min-height: 2.4rem;
  }

  .form-select option {
    font-size: 1rem;
  }
}
