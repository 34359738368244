.wrapper {
  width: 100vw;
  height: 100vh;
  user-select: none;
}
.content {
  position: relative;
  width: 100vw;
  height: 93vh;
}
