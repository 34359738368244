.advancedsearch-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
  height: 100%;
}

.advancedsearch-layer {
  width: 100%;
  height: 30px;
}

.advancedsearch-layer select {
  width: 100%;
  height: 100%;
}

.advancedsearch-columns {
  overflow-y: scroll;
  width: 100%;
  height: 120px;
  border: 1px solid var(--grey-color-700);
}

.advancedsearch-columns-options {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.5rem 0 0 0.5rem;
}

.advancedsearch-columns-options button {
  border: none;
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  width: 100%;
}

.advancedsearch-columns-options button:hover {
  cursor: pointer;
  background-color: var(--grey-color-200);
}

.advancedsearch-query {
  display: inline-flex;
  height: 120px;
  gap: 1rem;
}

.advancedsearch-operators {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  width: 150px;
  height: 100%;
}

.advancedsearch-operators-cols {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;
  width: 50px;
}

.advancedsearch-operators-cols button {
  padding: 0.1rem;
  height: auto;
  background: white;
  background: linear-gradient(
    0deg,
    white,
    var(--grey-color-200) 100%
  );
  border: solid 0.1rem var(--grey-color-700);
  border-radius: 0.3rem;
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.advancedsearch-operators-cols button:hover {
  background: white;
  box-shadow: 0.1rem 0.2rem 0.3rem var(--grey-color-700);
}

.advancedsearch-suggestions {
  overflow: scroll;
  width: 350px;
  height: 100%;
  border: 1px solid var(--grey-color-700);
  background: white;
}

.advancedsearch-suggestions-options {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 100%;
  background: white;
}

.advancedsearch-suggestions-options button {
  border: none;
  display: inline-flex;
  justify-content: flex-start;
  white-space: nowrap;
  background: white;
  padding: 0.5rem 1rem;
  width: 100%;
  font-family: var(--font-inter);
  font-weight: 500;
  font-size: 1.2rem;
}

.advancedsearch-suggestions-options button:hover {
  cursor: pointer;
  background-color: var(--grey-color-200);
}

.advancedsearch-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
}

.advancedsearch-input label {
  border-radius: 1rem 1rem 0 0;
}

.advancedsearch-input .form-group {
  width: 100%;
  height: 100%;
}

.advancedsearch-input textarea {
  width: 100%;
  height: 100%;
}
