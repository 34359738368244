.edit-role-form {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 40%;
  z-index: 2;
  background: var(--blue-color-800);
  border-radius: 0.5rem;
}

.edit-role-admin {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-role-admin .form-check {
  width: auto;
  gap: 1.5rem;
}

.edit-role-admin label {
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
}

.edit-role-admin input {
  accent-color: red;
  cursor: pointer;
}

.edit-role-normal {
  height: calc(100% - 6rem);
  width: 100%;
}

.edit-role-action {
  position: relative;
  width: 100%;
  height: 3rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--grey-color-700);
  border-radius: 0 0 0.5rem 0.5rem;
}

.edit-role-action button {
  margin: 0 0.5rem 0 0.5rem;
  height: 90%;
  border-radius: 0.5rem;
  background: var(--grey-color-50);
  background: linear-gradient(
    0deg,
    var(--grey-color-50),
    var(--grey-color-200) 100%
  );
}
