.form-label {
  align-self: flex-start;
  text-align: center;
  min-width: 20rem;
  padding: 0.5rem;
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: 0.5rem 0.5rem 0 0;
  color: white;
  background: var(--grey-color-700);
}

@media (max-width: 1024px) {
  .form-label {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .form-label {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .form-label {
    font-size: 1rem;
  }
}
