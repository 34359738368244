.form-check {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  padding: 0.2rem;
  cursor: pointer;
  color: var(--color-black)
}

.form-check input {
  width: 1.3rem;
  height: 1.3rem;
}

.form-check label {
  position: relative;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: var(--font-inter);
  width: 100%;
}

@media (max-width: 1024px) {
  .form-check input {
    width: 1.2rem;
    height: 1.2rem;
  }
  
  .form-check label {
    font-size: 1.1rem;
  }
}

@media (max-width: 600px) {
  .form-check input {
    width: 1.1rem;
    height: 1.1rem;
  }
  
  .form-check label {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .form-check input {
    width: 1rem;
    height: 1rem;
  }
  
  .form-check label {
    font-size: 0.9rem;
  }
}
