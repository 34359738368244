.theme-background {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
}
.theme-image {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
