.login-container {
  margin: 0;
  padding: 0rem;
  position: relative;
  background: var(--grey-color-50);
  border: solid 0.1rem var(--grey-color-700);
  box-shadow: 0.2rem 0.4rem 0.6rem var(--grey-color-700);
  width: 40rem;
  height: auto;
  border-radius: 0.8rem;
}

.login-title {
  position: relative;
  line-height: 4vh;
  width: 100%;
  background-color: var(--blue-color-600);
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.login-form {
  position: relative;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.login-form .form-group {
  width: 80%;
}

.login-form .form-group label {
  padding: 0.3rem 0 0.3rem 0;
  width: 100%;
}

.login-form .form-group input {
  border-radius: 0 0 0.5rem 0.5rem;
}

.login-btn button,
.guestlogin-btn button {
  position: relative;
  padding: 0.3rem;
  height: 2.8rem;
  width: auto;
  background: var(--grey-color-50);
  background: linear-gradient(
    0deg,
    var(--grey-color-50),
    var(--grey-color-200) 100%
  );
}

.forget-password button {
  height: 2.8rem;
  color: var(--blue-color-800);
  border: none;
  background: transparent;
}

.forget-password button:hover {
  color: var(--blue-color-600);
}

.login-error {
  position: relative;
  padding: 1rem;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.3rem;
  color: rgba(255, 0, 0, 0.781);
  background: yellow;
  border-radius: 5rem;
}

@media (max-width: 1024px) {
  .login-container {
    width: 37.5rem;
  }

  .login-title {
    font-size: 1.3rem;
  }

  .login-btn button,
  .guestlogin-btn button,
  .forget-password button {
    height: 2.7rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 600px) {
  .login-container {
    width: 35rem;
  }

  .login-title {
    font-size: 1.2rem;
  }

  .login-btn button,
  .guestlogin-btn button,
  .forget-password button {
    height: 2.6rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .login-container {
    width: 30rem;
  }

  .login-title {
    font-size: 1.1rem;
  }

  .login-btn button,
  .guestlogin-btn button,
  .forget-password button {
    height: 2.5rem;
    font-size: 0.9rem;
  }
}
