.tabcontrols {
  position: absolute;
  left: 100%;
  top: 0;
  width: 0;
  height: 100%;
}

.tabcontrols.minimize {
  height: 60%;
}

.tabcontrols .tabcontrol.active,
.tabcontrols .tabcontrol.active:hover {
  background-color: var(--blue-color-600);
}

.tabcontrol span {
  margin-left: 0.8rem;
  position: absolute;
  padding: 0.2rem;
  width: auto;
  min-width: 15rem;
  height: auto;
  min-height: 2.5rem;
  font-family: var(--font-inter);
  font-size: 1rem;
  color: white;
  background: var(--grey-color-700);
  z-index: 2;
  border-radius: 0.3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}

.tabcontrol span::after {
  content: " ";
  position: absolute;
  top: 40%; /* At the bottom of the tooltip */
  right: 100%;
  border-style: solid;
  border-color: var(--grey-color-700) transparent transparent
    transparent;
  transform: rotate(90deg);
}

.tabcontrol:hover span {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl.mapboxgl-ctrl-group button span {
  margin-left: 0.8rem;
  position: absolute;
  padding: 0.2rem;
  width: auto;
  min-width: 15rem;
  height: auto;
  min-height: 2.5rem;
  font-family: var(--font-inter);
  font-size: 1rem;
  color: white;
  background: var(--grey-color-700);
  z-index: 2;
  border-radius: 0.3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl.mapboxgl-ctrl-group button span::after {
  content: " ";
  position: absolute;
  top: 40%; /* At the bottom of the tooltip */
  right: 100%;
  border-style: solid;
  border-color: var(--grey-color-700) transparent transparent
    transparent;
  transform: rotate(90deg);
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl.mapboxgl-ctrl-group button:hover span {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s;
}

.mapboxgl-ctrl-custom {
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-custom .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  margin: 10px 0 0 10px;
}

.mapboxgl-ctrl-custom .mapboxgl-ctrl-top-left .mapboxgl-ctrl img {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.mapboxgl-ctrl-custom .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl img {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

/* CSS RESPONSIVE */
@media (max-width: 900px) {
  .tabcontrols.minimize {
    height: 100%;
  }
}
